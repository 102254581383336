import React,{ Fragment, useRef } from 'react'
import Footer from '../components/Footer/Footer';
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
import contactus from "../assests/images/contactus.jpg"
const ContactUs = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm(
          // "service_j96cda2",
          // "template_6o6yf33",
          // form.current,
          // "OmTWDTFDzwlm0U7j9"
        )
        .then(
          (result) => {
            console.log(result.text);

            alert("Message sent");
          },
          (error) => {
            console.log(error.text);
            alert("Error:",error.text);
          }
        );
    };
  
    return (
        <Fragment>
            
            <Container>
          
          <Col lg="6" md="6" sm="12" className="m-auto">
           <img src={contactus} alt="contact us img" style={{width:"90%"}}/>
            </Col>
            <Col lg="6" md="6" sm="12" className="m-auto">
             <StyledContactForm>
              <main>
             <div class="form-container">
             <center><h2>Contact Us</h2></center>
        <form ref={form} onSubmit={sendEmail} className='contact-form'>

          <label>Name</label>
          <input type="text" name="user_name" />
          <label>Email</label>
          <input type="email" name="user_email" />
          <label>Message</label>
          <textarea name="message" />
          <input type="submit" value="Send" />
        </form>
        </div>
        </main>
      </StyledContactForm>
      </Col>
     
      </Container>
         <Footer />
         </Fragment>
     
    );
  };
  
 

export default ContactUs


// npm i @emailjs/browser


// Styles
const StyledContactForm = styled.div`
  

  main {
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            text-align: center;
        }
            
        .form-container {
            width: 100%;
            max-width: 500px;
            background-color: white;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
            margin-bottom: 40px;
        }
             .contact-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: #24a0ed;
      color: white;
      border: none;
    }
  }
`;