import React, { Fragment, useState } from 'react';
import Footer from '../Footer/Footer';
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

const Jobseeker = () => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_mobile: '',
    user_education: '',
    user_passout: '',
    user_resume: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      user_resume: e.target.files[0]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jobseekers`, {
        method: 'POST',
        body: data,
      });

      if (!response.ok) {
        throw new Error('Failed to add job seeker');
      }

      alert('Job seeker added successfully!');
      setFormData({
        user_name: '',
        user_email: '',
        user_mobile: '',
        user_education: '',
        user_passout: '',
        user_resume: null
      });
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to add job seeker');
    }
  };

  return (
    <Fragment>
 <StyledContactForm>
 <br></br>
            <main>
            <h2>Job Seeker</h2>
            <div class="form-container">
              <form onSubmit={handleSubmit} className="jobseeker-form">
                <label>Name</label>
                <input type="text" name="user_name" value={formData.user_name} onChange={handleChange} required />
                <label>Email</label>
                <input type="email" name="user_email" value={formData.user_email} onChange={handleChange} required />
                <label>Mobile</label>
                <input type="number" name="user_mobile" value={formData.user_mobile} onChange={handleChange} required />
                <label>Education</label>
                <input type="text" name="user_education" value={formData.user_education} onChange={handleChange} required />
                <label>Passout Year</label>
                <input type="number" name="user_passout" value={formData.user_passout} onChange={handleChange} required />
                <label>Upload Resume</label>
                <input type="file" name="user_resume" onChange={handleFileChange} required />
                <button type="submit">Submit</button>
              </form>
              </div>
              </main>
            </StyledContactForm>
         
      <Footer />
    </Fragment>
  );
};

export default Jobseeker;

// Styles
const StyledContactForm = styled.div`
 main {
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            text-align: center;
        }
            
        .form-container {
            width: 100%;
            max-width: 500px;
            background-color: white;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
            margin-bottom: 40px;
        }
             .jobseeker-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
    
        .jobseeker-form label {
            margin-top: 10px;
            width: 100%;
            text-align: left;
        }
          .jobseeker-form input {
            margin-top: 5px;
            padding: 8px;
            width: 100%;
            border-radius: 5px;
            box-sizing: border-box;
        }
    
        .jobseeker-form button {
            margin-top: 20px;
            padding: 10px 20px;
            background-color: rgb(85, 170, 222);
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }
    
        .jobseeker-form button:hover {
            background-color: #379cdf;
        }
  
  
`;
