import React,{Fragment} from 'react';
import { useNavigate, Navigate,Link } from 'react-router-dom';

import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem('isAdmin');

  if (!isAdmin) {
    return <Navigate to="/admin/login" />;
  }

  const handleLogout = () => {
    localStorage.removeItem('isAdmin');
    navigate('/admin/login');
  };

  return (
    <div>
     <Fragment>
    <Container>
        <Row>
            <Col lg="4">
    <Link to="/jobseekerdata">
          <button className="btn">Job Seekers</button>
      </Link> 
      </Col>
      <Col lg="4">
      
              <Link to="/employeerdata">
                  <button className="btn">Employeers</button>
              </Link>
              </Col>
              <Col lg="4">
              <Link to="/admissiondata">
                  <button className="btn">Admissions</button>
              </Link>
              </Col>
          </Row>
          </Container>
          
          </Fragment>
      {/* <button onClick={handleLogout}>Logout</button> */}
    </div>
  );
};

export default AdminDashboard;


