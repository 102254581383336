import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";

import chooseImg from "../../assests/images/wc.jpg";
import "./choose-us.css";

import ReactPlayer from "react-player";

const ChooseUs = () => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <section class="about-container">
<div class="about-right">
    <h2>Why Choose Us</h2>
    <p>MNS Solutions has a highly skilled set of talents that are built on referrals and successful partnerships. Our recruiters are exceptionally skilled in identifying top talent and matching them with the client’s needs. We have an 85% fill ratio for every open position we partner for. The contract renewal rate for our consultant is 90%. 60% of our consultants are converted to full-time employees. To place our client’s interests first. To lead our profession in creating value for our clients through the assessment and recruitment of management resources.</p>
</div>
<div class="about-left">
    <img src={chooseImg} alt="why choose us" />
</div>
</section>
//     <section>
//       <Container>
//         <Row>
//           <Col lg="6" md="6">
//             <div className="choose__content">
//               <h2>Why Choose Us</h2>
//               <p>
//               MNS Solutions has a highly skilled set of talents that are built on referrals and successful partnerships.

// •       Our recruiters are exceptionally skilled in identifying top talent and matching them with the client’s needs.
// •       We have an 85% fill ratio for every open position we partner for.
// •       The contract renewal rate for our consultant is 90%.
// •       60% of our consultants are converted to full-time employees.

// •       To place our client’s interests first.
// •       To lead our profession in creating value for our clients through the assessment and recruitment of management resources.
//               </p>
//             </div>
//           </Col>

//           <Col lg="6" md="6">
//             <div className="choose__img">
//               {showVideo ? (
//                 <ReactPlayer
//                   url="https://www.youtube.com/watch?v=qFp27TR4Yew"
//                   controls
//                   width="100%"
//                   height="350px"
//                 />
//               ) : (
//                 <img src={chooseImg} alt="" className="w-100" />
//               )}

//               {!showVideo && (
//                 <span className="play__icon">
//                   <i
//                     class="ri-play-circle-line"
//                     onClick={() => setShowVideo(!showVideo)}
//                   ></i>
//                 </span>
//               )}
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </section>
  );
};

export default ChooseUs;
