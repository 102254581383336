import React, { useState,Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
import Footer from '../Footer/Footer';

const AdminLogin = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { username, password } = formData;

    if (username === 'admin' && password === 'mns123') {
      // Store a flag indicating successful login, if needed
      localStorage.setItem('isAdmin', true);
      navigate('/admin/dashboard');
    } else {
      alert('Invalid username or password');
    }
  };

  return (
    <Fragment>
      <Container>
        <Row>
          <Col lg="5" md="5" className="m-auto">
            <h2>Admin Login</h2>
            <StyledLoginForm>
              <form onSubmit={handleSubmit}>
                <label>Username</label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <input type="submit" value="Login" />
              </form>
            </StyledLoginForm>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default AdminLogin;

// Styles
const StyledLoginForm = styled.div`
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: #24a0ed;
      color: white;
      border: none;
    }
  }
`;
