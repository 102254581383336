import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";

import "./footer.css";

const footerQuickLinks = [
  {
    display: "Home",
    url: "#",
  },
  {
    display: "About US",
    url: "#",
  },

  {
    display: "Courses",
    url: "#",
  },

  {
    display: "Blog",
    url: "#",
  },
];

const footerInfoLinks = [
  {
    display: "Privacy Policy",
    url: "#",
  },
  {
    display: "Membership",
    url: "#",
  },


  {
    display: "Terms of Service",
    url: "#",
  },
];

const Footer = () => {
  return (
    <footer class="footer">
    <div class="containers">
        <div class="rows">
            <div class="mb-4 col-md-6 col-lg-3">
                <h2 class="d-flex align-items-center gap-1">
                    <i class="ri-pantone-line"></i> MNS Solutions</h2>
                <div class="follows">
                    <p class="mb-0">Follow us on social media</p>
                    <span><a href="https://www.youtube.com/channel/UC2lJ9Ge7A4CqWdejXQrTDuQ"><i class="fab fa-youtube"></i></a></span>
                    <span><a href="https://www.instagram.com/mnssolutionsgroup?igsh=MWhvdDd2OG50eDQ4ZA=="><i class="fab fa-instagram"></i></a></span>
                    <span><a href="https://www.linkedin.com/in/mns-solutions-b6505b30b"><i class="fab fa-linkedin"></i></a></span>
                    <span><a href="https://x.com/MNSSOLUTIONS"><i class="fab fa-twitter"></i></a></span>
                    <span><a href="https://wa.me/message/HZ3IUDYTVBASG1"><i class="fab fa-whatsapp"></i></a></span>
                </div>
            </div>
            <div class="mb-4 col-md-6 col-lg-3">
                <h5 class="fw-bold">Explore</h5>
                <ul class="link__list list-group">
                    <li class="border-0 ps-0 link__item list-group-item"><a href="#">Home</a></li>
                    <li class="border-0 ps-0 link__item list-group-item"><a href="#">About US</a></li>
                    <li class="border-0 ps-0 link__item list-group-item"><a href="#">Courses</a></li>
                    <li class="border-0 ps-0 link__item list-group-item"><a href="#">Blog</a></li>
                </ul>
            </div>
            <div class="mb-4 col-md-6 col-lg-3">
                <h5 class="fw-bold">Information</h5>
                <ul class="link__list list-group">
                    <li class="border-0 ps-0 link__item list-group-item"><a href="#">Privacy Policy</a></li>
                    <li class="border-0 ps-0 link__item list-group-item"><a href="#">Membership</a></li>
                    <li class="border-0 ps-0 link__item list-group-item"><a href="#">Terms of Service</a></li>
                </ul>
            </div>
            <div class="col-md-6 col-lg-3">
                <h5 class="fw-bold">Get in Touch</h5>
                <p>Address: 301, park avenue, patrika nagar, Madhapur, Hyderabad</p>
                <p>Telangana 500084</p>
                <p>Phone: +91 6305427016, +91 7981962432</p>
                <p>Email: contact@mnssolutionsgroup.com</p>
            </div>
        </div>
    </div>
</footer>
    // <footer className="footer">
    //   <Container>
    //     <Row>
    //       <Col lg="3" md="6" className="mb-4">
    //         <h2 className=" d-flex align-items-center gap-1">
    //           <i class="ri-pantone-line"></i> MNS Solutions
    //         </h2>

    //         <div className="follows">
    //           <p className="mb-0">Follow us on social media</p>
    //           <span>
    //             {" "}
    //             <a href="facebook.com">
    //               <i class="ri-facebook-line"></i>
    //             </a>
    //           </span>

    //           <span>
    //             {" "}
    //             <a href="facebook.com">
    //               <i class="ri-instagram-line"></i>
    //             </a>
    //           </span>

    //           <span>
    //             {" "}
    //             <a href="facebook.com">
    //               <i class="ri-linkedin-line"></i>
    //             </a>
    //           </span>

    //           <span>
    //             {" "}
    //             <a href="facebook.com">
    //               <i class="ri-twitter-line"></i>
    //             </a>
    //           </span>
    //         </div>
    //       </Col>

    //       <Col lg="3" md="6" className="mb-4">
    //         <h6 className="fw-bold">Explore</h6>
    //         <ListGroup className="link__list">
    //           {footerQuickLinks.map((item, index) => (
    //             <ListGroupItem key={index} className="border-0 ps-0 link__item">
    //               {" "}
    //               <a href={item.url}>{item.display}</a>
    //             </ListGroupItem>
    //           ))}
    //         </ListGroup>
    //       </Col>

    //       <Col lg="3" md="6" className="mb-4">
    //         <h6 className="fw-bold">Information</h6>
    //         <ListGroup className="link__list">
    //           {footerInfoLinks.map((item, index) => (
    //             <ListGroupItem key={index} className="border-0 ps-0 link__item">
    //               {" "}
    //               <a href={item.url}>{item.display}</a>
    //             </ListGroupItem>
    //           ))}
    //         </ListGroup>
    //       </Col>

    //       <Col lg="3" md="6">
    //         <h6 className="fw-bold">Get in Touch</h6>

    //         <p>Address: 301,park avenue, patrika nagar, Madhapur, Hyderabad</p>
    //         <p>Telangana 500084</p>
    //         <p> Phone: +91 6305427016, +91 7981962432 </p>
    //         <p>Email: Mnssolutionsceo@gmail.com</p>
    //       </Col>
    //     </Row>
    //   </Container>
    // </footer>
  );
};

export default Footer;
