import React, { Fragment, useState } from 'react';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

const Employeer = () => {
  const [formData, setFormData] = useState({
    company_name: '',
    hr_name: '',
    employeer_email: '',
    employeer_mobile: '',
    requirement: '',
    jd: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/employeers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit data');
      }

      alert('Data submitted successfully!');
      setFormData({
        company_name: '',
        hr_name: '',
        employeer_email: '',
        employeer_mobile: '',
        requirement: '',
        jd: '',
      });
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to submit data');
    }
  };

  return (
    <Fragment>
       <StyledContactForm>
       <br></br>
      <main>
            <h2>Employeer</h2>
           
            <div class="form-container">
              <form onSubmit={handleSubmit} className='jobseeker-form'>
                <label>Company Name</label>
                <input
                  type="text"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  required
                />
                <label>HR Name</label>
                <input
                  type="text"
                  name="hr_name"
                  value={formData.hr_name}
                  onChange={handleChange}
                  required
                />
                <label>Email</label>
                <input
                  type="email"
                  name="employeer_email"
                  value={formData.employeer_email}
                  onChange={handleChange}
                  required
                />
                <label>Mobile</label>
                <input
                  type="number"
                  name="employeer_mobile"
                  value={formData.employeer_mobile}
                  onChange={handleChange}
                  required
                />
                <label>Job Title</label>
                <input
                  type="text"
                  name="requirement"
                  value={formData.requirement}
                  onChange={handleChange}
                />
                <label>Job Description</label>
                <textarea
                  name="jd"
                  value={formData.jd}
                  onChange={handleChange}
                />
                <button type="submit">Submit</button> 
              </form>
              </div>
            
            </main>
            </StyledContactForm>
      <Footer />
    </Fragment>
  );
};

export default Employeer;

// Styles
const StyledContactForm = styled.div`
  main {
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            text-align: center;
        }
            
        .form-container {
            width: 100%;
            max-width: 500px;
            background-color: white;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
            margin-bottom: 40px;
        }
             .jobseeker-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
    
        .jobseeker-form label {
            margin-top: 10px;
            width: 100%;
            text-align: left;
        }
          .jobseeker-form input {
            margin-top: 5px;
            padding: 8px;
            width: 100%;
            border-radius: 5px;
            box-sizing: border-box;
        }
    
        .jobseeker-form button {
            margin-top: 20px;
            padding: 10px 20px;
            background-color: rgb(85, 170, 222);
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }
    
        .jobseeker-form button:hover {
            background-color: #379cdf;
        }
  
  
`;