import React,{ Fragment, useState  } from 'react'
import Footer from '../components/Footer/Footer';

import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";


const apiUrl = process.env.REACT_APP_API_URL;

const Admissions = () => {
console.log(apiUrl)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    course: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admissions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to add user');
      }

      alert('Admission added successfully!');
      setFormData({ name: '', email: '', mobile: '', course: '' });
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to add user');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
    return (
      <Fragment>
      <StyledContactForm>
        <br></br>
      <main>
            <h2>Admissions</h2>
            <div class="form-container">
            
        <form  onSubmit={handleSubmit} className="admission-form">
          <label>Name</label>
          <input type="text" name="name" required value={formData.name} onChange={handleChange} />
          <label>Email</label>
          <input type="email" name="email" required value={formData.email} onChange={handleChange} />
          <label>Mobile</label>
          <input type="number" name="mobile" required value={formData.mobile} onChange={handleChange} />
          <label>Select Course</label>
          <select name="course" required value={formData.course} onChange={handleChange}>
            <option value="">Select</option>
            <option value="inter">Intermediate</option>
            <option value="degree">Degree</option>
            <option value="bba">BBA</option>
            <option value="btech">B.Tech</option>
            <option value="mca">MCA</option>
            <option value="mba">MBA</option>
          </select>
          <input type="submit" value="Submit" />
        </form>
        </div>
        </main>
      </StyledContactForm>
     
         <Footer />
         </Fragment>
     
    );
  };
  
 

export default Admissions


// npm i @emailjs/browser


// Styles
const StyledContactForm = styled.div`
 main {
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            text-align: center;
        }
            
        .form-container {
            width: 100%;
            max-width: 500px;
            background-color: white;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
            margin-bottom: 40px;
        }
             .admission-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
    
        .admission-form label {
            margin-top: 10px;
            width: 100%;
            text-align: left;
        }
          .admission-form input {
            margin-top: 5px;
            padding: 8px;
            width: 100%;
            border-radius: 5px;
            box-sizing: border-box;
        }
              .admission-form select {
            margin-top: 5px;
            padding: 8px;
            width: 100%;
            border-radius: 5px;
            box-sizing: border-box;
        }
    
        input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: #24a0ed;
      color: white;
      border: none;
    }
  
  
`;
