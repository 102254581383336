import React from "react";
import { Container, Row, Col } from "reactstrap";
import mnshome from "../../assests/images/mnshome.jpg";
// import mnshome from "../../assets/images/mnshome.JPG";
import "./hero-section.css";

const HeroSection = () => {
  return (
    <>
    <br /><br />
    <div class="about-container">
    <div class="left-side">
        <h1>Anytime Anywhere<br />Learn on your<br />Suitable Schedule</h1>
        <p>MNS Solutions is a professionally managed organization providing manpower services to various corporate clients in India. It is a boutique staffing company serving Hyderabad since 2015.</p>
        <div class="search-container">
            <input type="text" placeholder="Search" class="search-input" />
            <button class="search-button">Search</button>
        </div>
    </div>
    <div class="right-side">
         {/* <img src={mnshome} alt="Learn on your Schedule" />  */}
         <img src={mnshome} alt=""  />
    </div>
</div>
    {/* <section>
      
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="hero__content">
              <h2 className="mb-4 hero__title">
                Anytime Anywhere <br /> Learn on your <br /> Suitable Schedule
              </h2>
              <p className="mb-5">
              MNS Solutions is a professionally managed organization providing manpower services to various corporate clients in India. It is a boutique staffing company serving Hyderabad since 2015.
              </p>
            </div>
            <div className="search">
              <input type="text" placeholder="Search" />
              <button className="btn">Search</button>
            </div>
          </Col>

          <Col lg="6" md="6">
            <img src={heroImg} alt="" className="w-100 hero__img " />
          </Col>
        </Row>
      </Container>
    </section> */}
    </>
  );
};

export default HeroSection;
