import React from "react";
import "./testimonial.css";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";

import img from "../../assests/images/testimonial01.png";

const Testimonials = () => {
  // const settings = {
  //   infinite: true,
  //   dots: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   slidesToScroll: 1,
  // };
  document.addEventListener('DOMContentLoaded', function() {
    const items = document.querySelectorAll('.carousel-item');
    const dots = document.querySelectorAll('.dot');
    let currentIndex = 0;
    
    function showItem(index) {
    items.forEach((item, i) => {
        item.classList.toggle('active', i === index);
        dots[i].classList.toggle('active', i === index);
    });
    }
    
    function nextItem() {
    currentIndex = (currentIndex + 1) % items.length;
    showItem(currentIndex);
    }
    
    setInterval(nextItem, 3000); 
    
    showItem(currentIndex);
    });
    
    
  return (
    <>
    <section class="students-voice">
<h2>Our Students' Voice</h2>
<div class="content-wrapper">
    
    <div class="carousel-container">
        <div class="carousel">
            <div class="carousel-item active">
                <p>"MNS Solutions has transformed my career. The training and support were top-notch!"</p>
                <span>- Jane Doe</span>
            </div>
            <div class="carousel-item">
                <p>"Thanks to MNS Solutions, I secured my dream job in cybersecurity."</p>
                <span>- John Smith</span>
            </div>
            <div class="carousel-item">
                <p>"The hands-on experience I gained through MNS Solutions was invaluable."</p>
                <span>- Sarah Lee</span>
            </div>
        </div>
        <div class="carousel-dots">
            <span class="dot active"></span>
            <span class="dot"></span>
            <span class="dot"></span>
        </div>
    </div>
</div>
</section>
<div class="social-icons">
    <a href="https://www.pinterest.com" target="_blank"><i class="fab fa-pinterest"></i></a>
    <a href="https://www.dribbble.com" target="_blank"><i class="fab fa-dribbble"></i></a>
    <a href="https://www.apple.com" target="_blank"><i class="fab fa-apple"></i></a>
    <a href="https://www.google.com" target="_blank"><i class="fab fa-google"></i></a>
</div>
</>
    // <section>
    //   <Container>
    //     <Row>
    //       <Col lg="5" md="5" className="m-auto">
    //         <div className="testimonial__wrapper d-flex justify-content-between align-items-center ">
    //           <div className="testimonial__img w-100">
    //             <img src={img} alt="" className="w-100" />
    //           </div>
    //         </div>
    //       </Col>
    //       <Col lg="1" md="1">
            
    //       </Col>
    //       <Col lg="6" md="6">
    //           <div className="testimonial__content w-100">
    //             <h2 className="mb-4">Our Students Voice</h2>

    //             <Slider {...settings}>
    //               <div>
    //                 <div className="single__testimonial">
    //                   <h6 className="mb-3 fw-bold">
    //                     Excellent course of materials
    //                   </h6>
    //                   <p>
    //                     Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //                     Facilis saepe id voluptas molestiae. Aperiam corrupti
    //                     voluptas earum at molestiae neque!
    //                   </p>

    //                   <div className="student__info mt-4">
    //                     <h6 className="fw-bold">Jhon Doe</h6>
    //                     <p>California, United State</p>
    //                   </div>
    //                 </div>
    //               </div>

    //               <div>
    //                 <div className="single__testimonial">
    //                   <h6 className="mb-3 fw-bold">
    //                     Excellent course of materials
    //                   </h6>
    //                   <p>
    //                     Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //                     Facilis saepe id voluptas molestiae. Aperiam corrupti
    //                     voluptas earum at molestiae neque!
    //                   </p>

    //                   <div className="student__info mt-4">
    //                     <h6 className="fw-bold">Jhon Doe</h6>
    //                     <p>California, United State</p>
    //                   </div>
    //                 </div>
    //               </div>

    //               <div>
    //                 <div className="single__testimonial">
    //                   <h6 className="mb-3 fw-bold">
    //                     Excellent course of materials
    //                   </h6>
    //                   <p>
    //                     Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //                     Facilis saepe id voluptas molestiae. Aperiam corrupti
    //                     voluptas earum at molestiae neque!
    //                   </p>

    //                   <div className="student__info mt-4">
    //                     <h6 className="fw-bold">Jhon Doe</h6>
    //                     <p>California, United State</p>
    //                   </div>
    //                 </div>
    //               </div>
    //             </Slider>
    //           </div>
           
            
    //       </Col>
    //     </Row>
    //   </Container>
    // </section>
  );
};

export default Testimonials;
