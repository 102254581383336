import React from "react";
import { Container, Row, Col } from "reactstrap";
import courseImg1 from "../../assests/pic/1.png";
import courseImg2 from "../../assests/pic/2.png";
import courseImg3 from "../../assests/pic/3.png";
import "./courses.css";
import CourseCard from "./CourseCard";
import { Link } from 'react-router-dom';
const coursesData = [
  {
    id: "01",
    title: "Cybersecurity",
    lesson: 12,
    students: 12.5,
    rating: 5.9,
    imgUrl: courseImg1,
  },

  {
    id: "02",
    title: "AWS",
    lesson: 12,
    students: 12.5,
    rating: 5.9,
    imgUrl: courseImg2,
  },

  {
    id: "03",
    title: "Java",
    lesson: 12,
    students: 12.5,
    rating: 5.9,
    imgUrl: courseImg3,
  },
  
];

const Courses = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="12" className="mb-5">
            <div className="course__top d-flex justify-content-between align-items-center">
              <div className="course__top__left w-100">
                
                <h2>Batch Starts From July 10th</h2>
               
                {/* <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                  consequatur libero quod voluptatibus ullam quia quas, vitae
                  voluptatem recusandae reprehenderit!
                </p> */}
              </div>
             </div>
             <div className="course__top d-flex justify-content-between align-items-center">
              <div className="w-50">
              <h3>Our Popular Courses</h3>
              </div>
              <div className="w-50 text-end">
                <Link to="/allcourses">
                <button className="btn" style={{backgroundColor:"#28a745"}}>See All</button>
                </Link>
              </div>
            </div>
          </Col>
          {coursesData.map((item) => (
            <Col lg="4" md="6" sm="6">
              <CourseCard key={item.id} item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Courses;
